import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useEffect } from 'react'

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: calc(100vh - 120px);  
    width: 90vw;
    padding: 100px 5vw 20px 5vw;
    gap: 20px;

    @media (max-width: 1200px) {
        width: 95vw;
        padding: 100px 2.5vw 20px 2.5vw;
    }

    a {
        color: white;
        font-size: 2rem;
        margin: 0;
        font-weight: 600;
        transition: color 0.3s ease;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }

        @media (max-width: 500px) {
            font-size: 1.2rem;
        }

        &:hover {
            color: var(--primary-color);
        }
    }

    .hero-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 70%;
        transition: all 0.3s ease;

        h3 {
            color: white;
            font-size: 1.5rem;
            margin: 0;
            font-weight: 600;
            text-align: center;

            @media (max-width: 768px) {
                font-size: 1.2rem;
            }

            @media (max-width: 500px) {
                font-size: 1rem;
            }
        }

        @media (max-width: 1200px) {
            width: 95%;
        }
         
        @media (max-width: 1400px) {
            width: 80%;
        }
    }

    .hero-container.start-prompt {
        justify-content: flex-start; 
        align-items: flex-start;
        width: 70%;
        gap: 10px;
        transition: all 0.3s ease;

        h1 {
            font-size: 3rem;
        }

        h3 {
            font-size: 1.5rem;
        }
            
        @media (max-width: 1200px) {
            width: 95%;
        }

        @media (max-width: 1400px) {
            width: 80%;
        }
    }

    .hero-container.replied {
        justify-content: flex-start; 
        align-items: flex-start;
        width: 70%;
        height: calc(100% - 100px);
        gap: 10px;

        h1 {
            font-size: 3rem;
        }

        h3 {
            display: none;
        }

        .reply-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            align-items: flex-end;

            h2 {
                color: white;
                font-size: 2rem;
                margin: 0;
                font-weight: 600;
                text-align: left;
                width: 100%;
            }

            p {
                color: white;
                margin: 0;
                font-size: 1.2rem;
                margin: 0 0 10px 0;
            }
        }

        @media (max-width: 1200px) {
            width: 95%;
        }

        @media (max-width: 1400px) {
            width: 80%;
        }
    }

    .input-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 70%;

        @media (max-width: 1200px) {
            width: 95%;
        }
    }

    .prompt-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        opacity: 1;

        @media (max-width: 700px) {
            display: grid;
            grid-template-columns: 1fr 1fr; /* 2x2 layout */
            width: 100%;
        }
    }

    .prompt-container.start-prompt {
        opacity: 0;
        animation: ${fadeOut} 0.3s ease forwards;
    }

    .prompt-container.replied {
        display: none;
    }
    
    .dot-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        > div {
            width: 8px;
            height: 8px;
            background-color: white;
            border-radius: 50%;
            margin: 0 2.5px;
            animation: dot-bounce 1.2s infinite ease-in-out both;

            &:nth-child(1) {
                animation-delay: -0.32s;
            }

            &:nth-child(2) {
                animation-delay: -0.16s;
            }
        }

        @keyframes dot-bounce {
            0%,
            80%,
            100% {
                transform: scale(0);
            }
            40% {
                transform: scale(1);
            }
        }
    }
`;

const IGPTSection = ({ children }) => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Container>
            {children}
        </Container>
    )
}

export default IGPTSection
