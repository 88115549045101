import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 45px;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid white;
  position: relative;
  font-size: 20px;
  font-family: 'Modernist', sans-serif;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);

  /* Focus state for the container */
  &:focus-within {
    border: 2px solid var(--primary-color);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
`;

const StyledInput = styled.input`
    width: 90%;   
    height: 100%;
    background: transparent;
    border: none;
    color: white;
    outline: none;
    padding: 5px 0 0 10px;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Modernist', sans-serif;
`;

const FloatingLabel = styled.label`
  position: absolute;
  top: ${(props) => (props.focused || props.value ? '7px' : '50%')};
  left: 10px;
  color: ${(props) => (props.focused || props.value ? 'var(--primary-color)' : 'white')};
  font-size: ${(props) => (props.focused || props.value ? '12px' : '20px')};
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  transform: translateY(-50%);
`;

const SearchIcon = styled.div`
  position: absolute;
  display: flex;
  right: 7px;
  top: 50%;
  width: auto;
  height: 60%;
  aspect-ratio: 1;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
  color: black;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid white;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 2px solid var(--primary-color);
  }
`;

const SearchBarInput = ({ label, value, onChange, type, onClick }) => {
  const [isFocused, setIsFocused] = useState(false);

  // listen for enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <InputContainer>
      <FloatingLabel focused={isFocused} value={value}>
        {label}
      </FloatingLabel>
      <StyledInput
        value={value}
        onChange={onChange}
        type={type}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyPress={handleKeyPress}
      />
      <SearchIcon onClick={onClick}>
        <FontAwesomeIcon icon={faArrowRight} />
      </SearchIcon>
    </InputContainer>
  );
};

export default SearchBarInput;
