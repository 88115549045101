import React, { useState } from 'react'
import DGPTBackground from '../components/backgrounds/DGPTBackground'
import IGPTSection from '../components/sections/IGPTSection'
import SearchBarInput from '../components/inputs/SearchBarInput'
import MainButtonFlexible from '../components/buttons/MainButtonFlexible'
import axios from 'axios'
import { useError } from '../context/ErrorContext';
import { useAuth } from '../context/AuthContext'

import MainDownloadButton from '../components/buttons/MainDownloadButton'

import { fetchAuthSession } from 'aws-amplify/auth'

const IGPT = () => {
    const [message, setMessage] = useState('')
    const [startPrompt, setStartPrompt] = useState(false)
    const [reply, setReply] = useState('')
    const [insightsFile, setInsightsFile] = useState(null)

    const { openErrorModal } = useError();
    const { user } = useAuth();

    const prompts = [
        { header: 'Market Trend', body: 'of TikTok Shop for a millennial audience' },
        { header: 'Insights', body: 'on the LinkedIn targeted Ads on 30+ year olds' },
        { header: 'Analysis', body: 'of the Spotify and Youtube Music 2024 of pop' },
        { header: 'Predictions', body: 'on the future of Facebook and Twitter' },
    ]

    const getInSights = async (userMessage) => {
        try {
            setStartPrompt(true);
            setReply('');
            setInsightsFile(null);
            const session = await fetchAuthSession();
            if (!session || !session.tokens || !session.tokens.idToken) {
                console.error('No valid session or token found.');
                return;
            }

            const token = session.tokens.idToken;
            const payload = {
                headers: { Authorization: `Bearer ${token}` },
                body: {
                    session_id: Date.now().toString(),
                    message: userMessage,
                },
            }

            const response = await axios.post(
                'https://i-gpt.cognidex.ai/fusion_agent',
                payload.body,
                { headers: payload.headers }
            );

            const data = response.data;

            if (data && data.response && data.response.length > 0) {
                setStartPrompt(false);
                setMessage('');
                setReply(data.response);
                setInsightsFile(data?.pdf_s3_location);
            }

        } catch (error) {
            openErrorModal({
                errorHeader: 'Error fetching insights',
                errorMessage: 'Please try again later'
            });
            console.error('Error fetching insights:', error);
        }
    }

    return (
        <DGPTBackground>
            <IGPTSection>
                <div className={`hero-container ${reply.length > 0 ? 'replied' : ''} ${startPrompt ? 'start-prompt' : ''}`}>
                    <h1>
                        I-GPT
                    </h1>
                    <h3>
                        {startPrompt ? 'Awesome insights on your way...' : 'Unparalleled Insights from Actual Human Data'}
                    </h3>
                    {startPrompt && (
                        <div className='dot-loader'>
                            <div></div><div></div><div></div>
                        </div>
                    )}
                    <div className={`prompt-container ${reply.length > 0 ? 'replied' : ''} ${startPrompt ? 'start-prompt' : ''}`}>
                        {prompts.map((prompt, index) => (
                            <MainButtonFlexible
                                key={index}
                                header={prompt.header}
                                subheader={prompt.body}
                                onClick={() => setMessage(`${prompt.header} ${prompt.body}`)}
                            />
                        ))}
                    </div>
                    <div className='reply-container'>
                        {reply.length > 0 && (
                            <>
                                <h2>Your Insights</h2>
                                <p>{reply}</p>
                                {insightsFile && (
                                    <MainDownloadButton
                                        header={'Download'}
                                        subheader={'Insights as PDF'}
                                        onClick={() => window.open(insightsFile)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className='input-container'>
                    <SearchBarInput
                        label={'Enter a prompt'}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        type={'text'}
                        onClick={() => {
                            if (message.length === 0) {
                                openErrorModal({
                                    errorHeader: 'Empty Prompt',
                                    errorMessage: 'Please enter a valid prompt to get insights'
                                })
                                return;
                            } else if (!user) {
                                openErrorModal({
                                    errorHeader: 'Sign In Required',
                                    errorMessage: 'Please sign in to access this feature'
                                })
                                return;
                            } 
                            getInSights(message)
                        }}
                    />
                </div>
            </IGPTSection>
        </DGPTBackground>
    )
}

export default IGPT
