import React from 'react'
import styled from 'styled-components'

// Essentially the Hero section
const Container = styled.div`
    position: relative;
    height: 90vh;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10vh 5vw 0 5vw;
    gap: 20px;

    a {
        text-decoration: underline;
        color: white;
        transition: color 0.3s ease;
        
        &:hover {
            color: var(--primary-color);
        }
    }

    .button-container {
        display: flex;
        flex-direction: row;
        gap: 12px;
        min-height: 100px;
        justify-content: flex-start;
    }

    .logo-container {
        position: absolute;
        top: 50vh;
        width: 50vw;
        transform: translateY(-50%);
        right: 0;
        z-index: -1;

        img {
            width: 75vh;
            height: auto;
            transform: rotate(-45deg);
        }

        // to ensure logo does not overlap with heading
        @media (min-width: 1480px) {
            scale: 1.1;
            width: 40vw;
        }

        @media (max-width: 1500px) {
            opacity: 0.1;
        }

        @media (max-width: 768px) {
            transform: translateY(-50%) translateX(-30%) scale(0.8);
        }

        @media (max-width: 600px) {
            tansform: translateY(-50%) translateX(-50%) scale(0.5);
        }
        
        @media (max-width: 480px) {
            transform: translateY(-50%) translateX(-70%) scale(0.5);
        }
    }
`;

const FirstSection = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default FirstSection
