export const defaultDataPoolList = [
        {
            dataPoolId: 'facebook',
            dataPoolName: 'Facebook',
            maxIncentive: 150
        },
        {
            dataPoolId: 'x',
            dataPoolName: 'X (Twitter)',
            maxIncentive: 200
        },
        {
            dataPoolId: 'spotify',
            dataPoolName: 'Spotify',
            maxIncentive: 100
        },
        {
            dataPoolId: 'youtube',
            dataPoolName: 'YouTube',
            maxIncentive: 100
        },
        {
            dataPoolId: 'linkedin',
            dataPoolName: 'LinkedIn',
            maxIncentive: 100
        },
        {
            dataPoolId: 'tiktok',
            dataPoolName: 'TikTok',
            maxIncentive: 200
        }
    ]